:root {
  --primary-color: #f47200;
  --highlight-bg: #fff1e5;
  --focus-color: #f48425;
  --highlight-text-color: #ae4f00;
  --focus-ring: 0 0 0 1px var(--focus-color);
}
/*
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: var(--primary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: var(--primary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: var(--primary-color);
}
*/
@layer primeng {
  /*
  .p-link:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }

  .p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {

    box-shadow: 0 0 0 1px var(--focus-color);
    border-color: var(--primary-color);
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight.p-focus {
    background: var(--highlight-bg);
  }
  .p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
    box-shadow: 0 0 0 1px var(--focus-color);
    border-color: var(--primary-color);
  }
  .p-datepicker .p-datepicker-header .p-datepicker-prev:focus-visible,
  .p-datepicker .p-datepicker-header .p-datepicker-next:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: var(--primary-color);
  }
  .p-datepicker table td > span.p-highlight {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-datepicker table td > span:focus {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-datepicker .p-timepicker button:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-cascadeselect:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 1px var(--focus-color);
    border-color: var(--primary-color);
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight.p-focus {
    background: var(--highlight-bg);
  }
  */
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--primary-color);
    background: var(--primary-color);
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    /*box-shadow: 0 0 0 1px var(--focus-color);*/
    box-shadow: var(--focus-ring);
    border-color: var(--primary-color);
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--primary-color);
    background: var(--primary-color);
  }
  .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
    background: var(--primary-color);
  }
  .p-checkbox.p-variant-filled:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: var(--primary-color);
  }
  .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: var(--primary-color);
  }
  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: var(--primary-color);
  }
  /*
  .p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
    box-shadow: 0 0 0 1px var(--focus-color);
    border-color: var(--primary-color);
  }
  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 1px var(--focus-color);
    border-color: var(--primary-color);
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
    background: var(--highlight-bg);
  }
  */
  .p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    /*box-shadow: 0 0 0 1px var(--focus-color);*/
    box-shadow: var(--focus-ring);
  }
  .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #bebec4;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--primary-color);
  }
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #bd5900;
  }
  /*
  .p-inputtext:enabled:focus {
    box-shadow: 0 0 0 1px var(--focus-color);
    border-color: var(--primary-color);
  }
  .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-listbox:not(.p-disabled) .p-listbox-item.p-highlight.p-focus {
    background: var(--highlight-bg);
  }
  .p-listbox.p-focus {
    box-shadow: 0 0 0 1px var(--focus-color);
    border-color: var(--primary-color);
  }
  .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 1px var(--focus-color);
    border-color: var(--primary-color);
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
    background: var(--highlight-bg);
  }
  */
  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    /*box-shadow: 0 0 0 1px var(--focus-color);*/
    box-shadow: var(--focus-ring);
    border-color: var(--primary-color);
  }
  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: var(--primary-color);
    background: var(--primary-color);
  }
  .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: var(--primary-color);
    background: var(--primary-color);
    color: #ffffff;
  }
  .p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight {
    background: var(--primary-color);
  }
  .p-radiobutton.p-variant-filled .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: var(--primary-color);
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: var(--primary-color);
  }
  .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: var(--primary-color);
  }
  /*
  .p-rating .p-rating-item.p-focus {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
    color: var(--primary-color);
  }
  .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
    color: var(--primary-color);
  }
  .p-selectbutton .p-button.p-highlight {
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
  .p-selectbutton .p-button.p-highlight:hover {
    background: #bd5900;
    border-color: #bd5900;
  }
  .p-slider .p-slider-handle {
    border: 2px solid var(--primary-color);
  }
  .p-slider .p-slider-handle:focus {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-slider .p-slider-range {
    background: var(--primary-color);
  }
  .p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
  .p-togglebutton.p-button.p-highlight {
    background: var(--primary-color);
    border-color: var(--primary-color);
  }
  .p-togglebutton.p-button.p-highlight:hover {
    background: #bd5900;
    border-color: #bd5900;
  }
  .p-treeselect:not(.p-disabled).p-focus {
    box-shadow: 0 0 0 1px var(--focus-color);
    border-color: var(--primary-color);
  }
  .p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-button {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
  .p-button:not(:disabled):hover {
    background: #bd5900;
    color: #ffffff;
    border-color: #bd5900;
  }
  .p-button:not(:disabled):active {
    background: #bd5900;
    color: #ffffff;
    border-color: #bd5900;
  }
  .p-button.p-button-outlined {
    color: var(--primary-color);
  }
  .p-button.p-button-outlined:not(:disabled):hover {
    color: var(--primary-color);
  }
  .p-button.p-button-outlined:not(:disabled):active {
    background: rgba(244, 114, 0, 0.16);
    color: var(--primary-color);
    border: 1px solid;
  }
  .p-button.p-button-text {
    color: var(--primary-color);
  }
  .p-button.p-button-text:not(:disabled):hover {
    background: rgba(244, 114, 0, 0.04);
    color: var(--primary-color);
  }
  .p-button.p-button-text:not(:disabled):active {
    background: rgba(244, 114, 0, 0.16);
    color: var(--primary-color);
  }
  .p-button:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-button .p-badge {
    color: var(--primary-color);
  }
  .p-button.p-button-link {
    color: var(--primary-color);
  }
  .p-button.p-button-link:not(:disabled):hover {
    color: var(--primary-color);
  }
  .p-button.p-button-link:not(:disabled):focus {
    box-shadow: 0 0 0 0.2rem var(--focus-color);
  }
  .p-button.p-button-link:not(:disabled):active {
    color: var(--primary-color);
  }
  .p-speeddial-item.p-focus > .p-speeddial-action {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-carousel .p-carousel-content .p-carousel-prev:focus-visible,
  .p-carousel .p-carousel-content .p-carousel-next:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: var(--highlight-bg);
    color: var(--highlight-text-color);
  }
  .p-datatable .p-sortable-column .p-sortable-column-badge {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-datatable .p-sortable-column.p-highlight {
    color: var(--primary-color);
  }
  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--primary-color);
  }
  .p-datatable .p-sortable-column.p-highlight:hover {
    color: var(--primary-color);
  }
  .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: var(--primary-color);
  }
  .p-datatable .p-sortable-column:focus-visible {
    box-shadow: inset 0 0 0 0.15rem var(--focus-color);
  }
  .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus-visible,
  .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-datatable .p-datatable-tbody > tr:focus-visible {
    outline: 0.15rem solid var(--focus-color);
  }
  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: var(--highlight-bg);
    color: var(--highlight-text-color);
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 var(--highlight-bg);
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 var(--highlight-bg);
  }
  .p-datatable .p-column-resizer-helper {
    background: var(--primary-color);
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background: var(--highlight-bg);
    color: var(--highlight-text-color);
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
    color: var(--highlight-text-color);
  }
  .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
    color: var(--highlight-text-color);
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: var(--highlight-bg);
    color: var(--highlight-text-color);
  }
  .p-column-filter-menu-button:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-column-filter-clear-button:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus-visible {
    box-shadow: inset 0 0 0 0.15rem var(--focus-color);
  }
  .p-orderlist .p-orderlist-list-container.p-focus {
    box-shadow: 0 0 0 1px var(--focus-color);
    border-color: var(--primary-color);
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight.p-focus {
    background: var(--highlight-bg);
  }
  .p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: var(--highlight-bg);
    color: var(--highlight-text-color);
  }
  .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #6f91ff;
  }
  .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--highlight-bg);
    border-color: var(--highlight-bg);
    color: var(--highlight-text-color);
  }
  .p-picklist .p-picklist-list-wrapper.p-focus {
    box-shadow: 0 0 0 1px var(--focus-color);
    border-color: var(--primary-color);
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-picklist .p-picklist-list .p-picklist-item.p-highlight.p-focus {
    background: var(--highlight-bg);
  }
  .p-timeline .p-timeline-event-marker {
    border: 2px solid var(--primary-color);
  }
  .p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
    box-shadow: inset 0 0 0 0.15rem var(--focus-color);
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
    background: var(--primary-color);
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box.p-highlight:hover {
    background: var(--primary-color);
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: var(--highlight-bg);
    color: var(--highlight-text-color);
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: var(--highlight-text-color);
  }
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
  .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: var(--highlight-text-color);
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
    background-color: var(--highlight-bg);
    color: var(--highlight-text-color);
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: var(--highlight-text-color);
  }
  .p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-treetable .p-sortable-column {
    outline-color: var(--focus-color);
  }
  .p-treetable .p-sortable-column .p-sortable-column-badge {
    color: var(--highlight-text-color);
    background: var(--highlight-bg);
  }
  .p-treetable .p-sortable-column.p-highlight {
    color: var(--primary-color);
  }
  .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: var(--primary-color);
  }
  .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox.p-variant-filled .p-checkbox-box.p-highlight {
    background: var(--primary-color);
  }
  .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox.p-variant-filled:not(.p-disabled) .p-checkbox-box.p-highlight:hover {
    background: var(--primary-color);
  }
  .p-treetable .p-treetable-tbody > tr:focus-visible {
    outline: 0.15rem solid var(--focus-color);
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight {
    background: var(--highlight-bg);
    color: var(--highlight-text-color);
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
    color: var(--highlight-text-color);
  }
  .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
    color: var(--highlight-text-color);
  }
  .p-treetable .p-column-resizer-helper {
    background: var(--primary-color);
  }
  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus-visible {
    box-shadow: inset 0 0 0 1px var(--focus-color);
  }
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-panel .p-panel-header .p-panel-header-icon:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-scrollpanel .p-scrollpanel-bar:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-splitter .p-splitter-gutter .p-splitter-gutter-handle:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus-visible {
    box-shadow: inset 0 0 0 1px var(--focus-color);
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
  .p-tabview .p-tabview-nav-btn.p-link {
    color: var(--primary-color);
  }
  .p-tabview .p-tabview-nav-btn.p-link:focus-visible {
    box-shadow: inset 0 0 0 1px var(--focus-color);
  }
  .p-stepper-header .p-stepper-action:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-stepper .p-stepper-header .p-stepper-action:not(.p-disabled):focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-stepper .p-stepper-header.p-highlight .p-stepper-number {
    background: var(--highlight-bg);
    color: var(--highlight-text-color);
  }
  .p-stepper .p-stepper-header:not(.p-disabled):focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
    background-color: var(--primary-color);
  }
  .p-stepper.p-stepper-vertical .p-stepper-panel:has(~ .p-stepper-panel-active) .p-stepper-separator {
    background-color: var(--primary-color);
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-overlaypanel .p-overlaypanel-close {
    background: var(--primary-color);
  }
  .p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #bd5900;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:focus-visible,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-fileupload .p-fileupload-content.p-fileupload-highlight {
    border-color: 1px dashed var(--primary-color);
    background-color: var(--highlight-bg);
  }
  .p-fileupload-choose:not(.p-disabled):hover {
    background: #bd5900;
    border-color: #bd5900;
  }
  .p-fileupload-choose:not(.p-disabled):active {
    background: #bd5900;
    border-color: #bd5900;
  }
  .p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-dock .p-dock-item.p-focus {
    box-shadow: inset 0 0 0 0.15rem var(--focus-color);
  }
  .p-menu .p-menuitem-badge {
    background: var(--primary-color);
   }
  @media screen and (max-width: 960px) {
    .p-menubar .p-menubar-button:focus {
      box-shadow: 0 0 0 1px var(--focus-color);
    }
  }
  .p-panelmenu .p-panelmenu-header:not(.p-disabled):focus-visible .p-panelmenu-header-content {
    box-shadow: inset 0 0 0 1px var(--focus-color);
  }
  .p-slidemenu .p-slidemenu-backward:not(.p-disabled):focus {
    box-shadow: inset 0 0 0 1px var(--focus-color);
  }
  .p-slidemenu .p-menuitem-badge {
    background: var(--primary-color);
  }
  .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: var(--highlight-bg);
    color: var(--highlight-text-color);
  }
  .p-tabmenu .p-tabmenu-nav .p-menuitem-badge {
    background: var(--primary-color);
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus-visible {
    box-shadow: inset 0 0 0 1px var(--focus-color);
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
  .p-tabmenu .p-tabmenu-nav-btn.p-link {
    color: var(--primary-color);
  }
  .p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
    box-shadow: inset 0 0 0 1px var(--focus-color);
  }
  .p-message .p-message-close:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-toast .p-toast-message .p-toast-icon-close:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: var(--highlight-bg);
    color: var(--highlight-text-color);
  }
  .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: var(--highlight-bg);
    color: var(--highlight-text-color);
  }
  .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-badge {
    background: var(--primary-color);
  }
  .p-chip .pi-chip-remove-icon:focus-visible {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-inplace .p-inplace-display:focus {
    box-shadow: 0 0 0 1px var(--focus-color);
  }
  .p-metergroup .p-metergroup-meter {
    background: var(--primary-color);
  }
  .p-metergroup .p-metergroup-labels .p-metergroup-label-marker {
    background: var(--primary-color);
  }
  .p-progressbar .p-progressbar-value {
    background: var(--primary-color);
  }
  .p-tag {
    background: var(--primary-color);
  }
  */
}

/* Customizations to the designer theme should be defined here */
@layer primeng {
  .p-inputswitch.p-focus .p-inputswitch-slider {
    /*box-shadow: 0 0 0 2px var(--focus-color);*/
    box-shadow: none;
  }
  /*
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-color: var(--primary-color);
  }
  .p-button:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--focus-color), 0 1px 2px 0 rgba(0, 0, 0, 0);
  }
  */
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    /*box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--focus-color), 0 1px 2px 0 rgba(0, 0, 0, 0);*/
    box-shadow: none;
  }
  .p-radiobutton:not(.p-radiobutton-disabled) .p-radiobutton-box.p-focus {
    /*box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--focus-color), 0 1px 2px 0 rgba(0, 0, 0, 0);*/
    box-shadow: none;
  }
  /*
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 var(--primary-color);
  }
  .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 var(--primary-color);
  }
   */
}
