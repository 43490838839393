@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@import "styles/abstracts/mixins";
@import "styles/abstracts/colors";
@import "styles/abstracts/vars";
@import "styles/base/reset";
@import "styles/base/fixes";
@import "styles/base/typography";
@import "styles/base/helpers";
@import "styles/base/buttons";
@import "styles/base/forms";
@import "styles/components/drawflow";
@import "styles/components/minimap";
@import "styles/components/emoji";
@import "styles/components/accordion";
@import "styles/components/modals";
@import "styles/components/tooltip";
@import "styles/components/tables";
@import "styles/components/tabview";
@import "styles/components/dropdown";
@import "styles/sections/topbar";
@import "styles/clients/emailchef";

