.text2lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.thin-scrollbars {
  scrollbar-width: thin;
  scrollbar-color: var(--node-settings-scrollbar-handle-color) var(--node-settings-scrollbar-background-color);;

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 12px;
  }
}
