.p-placeholder, *::placeholder{
  color: var(--form-placeholders-color) !important;
  font-style: italic;
  font-weight: 100;
  &::after{
    content: "...";
  }
}
label{
  &:not(.p-radiobutton-label, p-checkbox-label, .p-inputswitch-label){
    color: var(--form-labels-color);
    text-transform: uppercase;
  }
}
.p-inputgroup-addon-sm{
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}

.highlight-error {
  .p-inputtext{
    border-top-color: #e24c4c !important;
    border-left-color: #e24c4c !important;
    border-bottom-color: #e24c4c !important;
  }
  .p-dropdown{
    border-top-color: #e24c4c !important;
    border-right-color: #e24c4c !important;
    border-bottom-color: #e24c4c !important;
  }
}
