.tooltip-preview{

  &.p-tooltip{
    max-width: 220px;

    img{
      width: 200px;
    }
  }
}
.p-tooltip .p-tooltip-text {
  background: var(--tooltip-background-color);
  color: var(--tooltip-text-color);
  padding: 0.75rem 0.75rem;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: var(--tooltip-background-color);
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: var(--tooltip-background-color);
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: var(--tooltip-background-color);
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: var(--tooltip-background-color);
}
