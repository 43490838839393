body.emailchef{

  --primary-color: #f47200;
  --primary-hover: #8e4200;
  --focus-bg-hover:rgba(244, 114, 0, 0.04);
  --focus-bg-active:rgba(244, 114, 0, 0.16);
  --focus-color: #f1ae74;
  --highlight-text-color: #8e4200;
  --focus-ring: 0 0 0 1px var(--focus-color);
  --stats-enter-tag-border-color: #f47200;
  --stats-enter-tag-text-color: var(--white);
  --stats-enter-tag-bg-color: #f47200;
  --stats-enter-tag-hover-bg-color: #8e4200;
  --stats-out-tag-border-color: #f47200;
  --stats-out-tag-text-color: var(--white);
  --stats-out-tag-bg-color: #f47200;
  --stats-out-tag-hover-bg-color: #8e4200;
  --stats-in-tag-border-color: v#f47200;
  --stats-in-tag-text-color: var(--white);
  --stats-in-tag-bg-color: #f47200;
  --stats-in-tag-hover-bg-color: #8e4200;
  --stats-email-border-color: #f47200;
  --stats-email-text-color: var(--white);
  --stats-email-bg-color: #f47200;
  --stats-email-hover-bg-color: #8e4200;
  --status-active-color: #43a047;
  --status-paused-color: #eea236;

  header{

    .topbar__back-button{
      display: none;
    }

    .p-button{
      text-transform: uppercase;
      .p-button-label{
        font-weight: 100;
      }
    }

    .status_tag.p-tag.p-tag-{
      &success{
        background-color: var(--status-active-color);
      }
      &warning{
        background-color: var(--status-paused-color);
      }
    }

  }

  .node-stats-details {

    .p-tabview-panels {
      display: none;
    }

    &-datepicker{
      display: none;

      &-icon {
        display: none;
      }

    }

  }

  .statistics-dialog .p-dialog{
    width: 94% !important;
  }

  .stats__reloading{
    background-color: var(--primary-color);
  }

  /* Tailwind edits */

  .p-button {
    transition: all .5s linear;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: var(--primary-color);
    color: var(--primary-color);
  }

  .p-datatable .p-sortable-column.p-highlight,
  .p-datatable .p-sortable-column.p-highlight:hover{
    color: var(--primary-color);

    .p-sortable-column-icon {
      color: var(--primary-color);
    }
  }


  .p-datepicker {

    table td{
      padding-left: 0;
      padding-right: 0;

      & > span {

        height: 3rem;
        width: 3rem;

        &.p-highlight{
          color: var(--primary-color);
          background-color: var(--focus-bg-active);
          border-radius: 0;

          &:has(.first),
          &:has(.last){
            background: var(--primary-color) ;
            color: var(--white);
          }

          span:hover{
            background: transparent !important;
          }

        }
      }
      &.p-datepicker-today{
        & > span{
          /*
          background: var(--primary-color);
          color: var(--white);
          */
          border-color: var(--primary-color);
          background-color: transparent;
        }
      }
    }
  }

  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 1px var(--focus-color);
    border-color: var(--primary-color);
  }

  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-color: var(--primary-color);
    color: var(--primary-color);
    background-color: var(--focus-bg-hover);
  }

}
