.node-stats-details{
  .p-tabview-nav{
    .p-disabled{
      display: none;
    }
  }
}
.webhook__{
  &log-container{
    .p-timeline-event {
      min-height: 50px
    }
  }
  &container{
    .webhook__log-loading{
      color: var(--text-color);
    }
    .webhook__log-closed, .webhook__log-opened{
      color: var(--text-color);
      opacity: .2;
      transition: all .25s;
    }
    &:hover {
      .webhook__log-closed, .webhook__log-opened{
        opacity: 1;
      }
    }
  }
}
