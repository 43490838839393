header {

  a,
  a:hover{
    color: var(--white);
  }

  .topbar__back-button{
    display: none;

    &:hover {
      background-color: rgba(255, 255, 255, .1);
      .fa{
        margin-left: -2px;
        margin-right: 2px;
      }
    }

  }

  .flow-name-container.p-inplace {

    .p-inplace-display{

      display: inline-flex;
      align-items: center;
      max-width: 100%;
      border: 1px solid transparent;

      &:not(.p-disabled) {

        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        color: var(--header-text-color);

        &:hover {
          background-color: rgba(255, 255, 255, .1);

          i.fa {
            opacity: 1;
          }
        }
      }
    }
    .p-inplace-content {

      display: inline-flex;
      border-radius: .5rem;
      border: 1px solid #999;
      width: 100%;
      background-color: #FFF;
      box-shadow: inset 0 0 0 1px #505050;

      &:has(input.ng-invalid){
        box-shadow: inset 0 0 0 1px var(--error-color);

        input::placeholder{
          color: var(--error-color) !important;
        }
      }

      input{
        background-color: transparent;
        /*color: var(--header-text-color);*/
        border: none;
        box-shadow: none !important;
        width: 100%;
        color: var(--text-color);
      }
      button{
        background:transparent;
        border: none;
        opacity: .8;
        box-shadow: none !important;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;

        &.flow-name-confirm-disabled{
          opacity: .2;
          pointer-events: none;
        }

        &:hover{
          opacity: 1;
          background-color: rgba(0, 0, 0, .05);
        }
      }
    }
  }
}

body.no-frame{
  .topbar__back-button {
    display: block;
  }
}
