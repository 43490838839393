.minimap-container {

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  max-height: 250px;

  #minimap {

    width: 100%;
    height: 100%;
    cursor: move;
    background-color: transparent;

    * {
      cursor: move;
      pointer-events: none;
    }

    .main-path {
      stroke: var(--minimap-edge-color) !important;
      stroke-width: 4 !important;
    }

    .mask {
      border: 1px solid var(--minimap-mask-border-color);
      border-radius: 2px;
      position: absolute;
      pointer-events: none;
      box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.03);

    }

    .drawflow-node {
      border: none;

      .node-container {
        border-radius: 1rem;

        &.category_ {

          &entrypoint {
              background-color: var(--minimap-element-entrypoint-background-color);
          }

          &wait {
            background-color: var(--minimap-element-wait-background-color);
          }

          &action {
            background-color: var(--minimap-element-action-background-color);
          }

          &condition {
            background-color: var(--minimap-element-condition-background-color);
          }

          &trigger {
            background-color: var(--minimap-element-trigger-background-color);
          }
        }
      }

      .invalid-node {
        --tw-ring-offset-width: 0;
        --tw-ring-offset-shadow: 0 0 0 0 0 transparent;
        --tw-ring-shadow: 0 0 0 0 0 transparent;
      }
    }

    .image-container-placeholder {
      border-color: transparent !important;
      background-color: transparent !important;

      i {
        color: var(--white);
        font-size: 12em !important;
        opacity: 1;
        color: var(--green-500);
      }
    }

    /* Hide flow elements - start */

    .condition-box {
      visibility: hidden;
    }

    .node-details {
      visibility: hidden;
    }

    .title-box {
      visibility: hidden !important;
    }

    .node-invalid-button {
      visibility: hidden;
    }

    .actions, .inputs, .outputs {
      visibility: hidden !important;
    }

    .exit-criteria{
      display: none !important;
    }

    span[class^='stats__']{
      display: none !important;
    }

    /* Hide flow elements - end */

  }
}
