.p-inputgroup-fix {
  .p-inputgroup-addon:last-child, .p-inputgroup button:last-child, .p-inputgroup input:last-child, .p-inputgroup > .p-inputwrapper:last-child > .p-component, .p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.swal2-container{
  z-index: 10000 !important;
}
.p-toast .p-toast-message.p-toast-message-error,
.p-inline-message.p-inline-message-error,
.p-message.p-message-error{
  background-color: var(--red-100) !important;
}
