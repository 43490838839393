.picmo__popupContainer {
  z-index: 11111111111 !important;

}

.emoji-popup {
  scale: 0.8;
  margin-top: -3.1rem;
  margin-right: -2.35rem;
}

.picmo__emojiButton:hover{
  border-radius: .375rem;
}
