.p-inputgroup-fix {
  .p-inputgroup-addon:last-child, .p-inputgroup button:last-child, .p-inputgroup input:last-child, .p-inputgroup > .p-inputwrapper:last-child > .p-component, .p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.swal2-container{
  z-index: 10000 !important;
}
.p-toast .p-toast-message.p-toast-message-error,
.p-inline-message.p-inline-message-error,
.p-message.p-message-error{
  background-color: var(--red-100) !important;
}

/* WEBHOOK code editor */

.cm-editor {
  /*font-size: 14px !important;*/
  font-size: 0.75rem !important;
  border-radius: 0.25rem;
}
.cm-content, .cm-gutter {
  min-height: 97px !important;
}
.cm-gutter {
  background-color: rgb(249 250 251 / var(--tw-bg-opacity, 1)) !important;
}
.cm-scroller{
  border-bottom-left-radius: 0.25rem;
  scrollbar-width: thin;
  scrollbar-color: var(--node-settings-scrollbar-handle-color) var(--node-settings-scrollbar-background-color);
}
.cm-focused{
  outline: none !important;
}
